import React        from 'react';
import {createRoot} from 'react-dom/client';
import Root         from './components/Root';

import './css/style.css';

import reportWebVitals from './reportWebVitals';

const strictRoot = (
	<React.StrictMode>
		<Root />
	</React.StrictMode>
);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(strictRoot);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
